/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  RiArrowRightSLine, 
  RiUserSearchLine, 
  RiDatabase2Line, 
  RiLineChartLine, 
  RiBookOpenLine, 
  RiHeartLine, 
  RiTimeLine 
} from "react-icons/ri"

const SoftwarePage = () => {
  return (
    <Layout>
      <SEO 
        title="HR Software Comparison | Best HR Technology Solutions"
        description="Compare the best HR software solutions including ATS, HRIS, Performance Management, LMS, Employee Engagement, and Time & Attendance systems."
        keywords={[
          "HR software comparison",
          "applicant tracking systems",
          "HRIS software",
          "performance management software",
          "learning management systems",
          "employee engagement platforms"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            HR Software Solutions
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Compare different types of HR software to find the right solutions for your organization.
            From recruitment to performance management, these platforms help streamline your HR processes.
          </p>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            {/* Applicant Tracking Systems */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/applicant-tracking-system" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiUserSearchLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Applicant Tracking Systems (ATS)
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Software that helps manage the recruitment process, from posting jobs to tracking candidates and managing applications.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* HRIS */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/hris" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiDatabase2Line />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Human Resource Information Systems (HRIS)
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Core HR software that manages employee data, payroll, benefits, and other essential HR functions.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Performance Management */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/performance-management" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiLineChartLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Performance Management Software
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Tools for tracking employee performance, setting goals, conducting reviews, and managing feedback.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Learning Management */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/learning-management-system" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiBookOpenLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Learning Management Systems (LMS)
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Platforms for employee training, development, and certification tracking to enhance workforce skills.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Employee Engagement */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/employee-engagement" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiHeartLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Employee Engagement Platforms
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Software focused on measuring and improving employee satisfaction, engagement, and company culture.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Time & Attendance */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/time-attendance" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiTimeLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Time & Attendance Software
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Tools for tracking employee work hours, managing schedules, and monitoring attendance with accuracy.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Payroll Providers */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/software/payroll-providers" sx={{
                textDecoration: 'none',
                color: 'text',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  '.compare-link': {
                    color: 'white',
                    bg: 'primary',
                    px: 2,
                    py: 1,
                    borderRadius: '4px'
                  }
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiDatabase2Line />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Payroll Providers
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Compare top payroll services that automate wage calculations, tax filing, and ensure accurate, compliant employee payments.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  className: 'compare-link',
                  transition: 'color 0.2s, background-color 0.2s'
                }}>
                  Compare Solutions <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
          </div>
          
          <div sx={{
            mt: 5,
            pt: 4,
            borderTop: '1px solid',
            borderColor: 'primary',
            textAlign: 'center'
          }}>
            <h2 sx={{
              fontSize: ['1.5rem', '1.75rem'],
              fontWeight: 600,
              mb: 3
            }}>
              How to Choose the Right HR Software
            </h2>
            <p sx={{
              fontSize: '1rem',
              maxWidth: '900px',
              mx: 'auto',
              mb: 4,
              lineHeight: 1.7
            }}>
              When selecting HR software for your organization, consider these key factors: your specific needs, budget, 
              company size, integration capabilities, user experience, mobile accessibility, data security, and vendor 
              support. Each solution offers different features and pricing models, so carefully evaluate how they align 
              with your HR strategy and organizational goals.
            </p>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mt: 6,
            mb: 4
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right HR Software?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              size, industry, and specific requirements to find the perfect software solution.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'white',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div sx={{
        maxWidth: '800px',
        mx: 'auto',
        py: 5,
        px: [3, 0]
      }}>
        <h2 sx={{ 
          fontSize: ['1.5rem', '1.65rem'],
          mb: 4,
          color: 'primary',
          textAlign: 'center'
        }}>Frequently Asked Questions About HR Software</h2>

        <div sx={{
          display: 'grid',
          gap: 4
        }}>
          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What's the difference between HRIS, ATS, and Performance Management software?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>An HRIS (Human Resource Information System) is a core platform for managing employee data, payroll, and benefits. An ATS (Applicant Tracking System) focuses specifically on the recruitment process. Performance Management software helps track goals, conduct reviews, and manage feedback. Often, these functionalities can be found integrated within a single HRIS or as standalone systems.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How do I choose the right HR software for my small business?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>For small businesses, prioritize ease of use, essential features (like payroll and basic record-keeping), scalability, and budget. Look for solutions designed for smaller teams that offer good customer support. Start with your most critical needs and choose software that can grow with you.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What should I consider regarding data security and privacy?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Data security is paramount. Look for vendors with robust security measures, including data encryption, regular backups, secure access controls, and compliance with data privacy regulations like GDPR or CCPA. Ask potential vendors about their security protocols and certifications.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Can different HR software systems integrate with each other?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Yes, many modern HR software solutions offer integrations with other systems (e.g., payroll, accounting, ATS). Check the integration capabilities of any software you're considering to ensure it works seamlessly with your existing tech stack. APIs (Application Programming Interfaces) are key for smooth integrations.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How much does HR software typically cost?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Costs vary widely based on the type of software, features included, number of employees, and vendor pricing models (e.g., per employee per month, subscription tiers). Basic HRIS might start from a few dollars per employee per month, while comprehensive suites can be significantly more. Always request detailed pricing and check for implementation or hidden fees.</p>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default SoftwarePage; 